<template>
  <div style="width: 100% ;padding: 10px">
    <div style="margin: 10px 0">
      <el-button type="primary" @click="add">添加员工</el-button>
    </div>
        <div style="margin: 10px 0">
          <el-input v-model="search" placeholder="请输入姓名或工号" style="width:35%"></el-input>
          <el-button type="primary" style="margin-left:5px" @click = "selectkey">查询</el-button>
          <el-button type="primary" @click="remove">重置</el-button>
        </div>
    <el-table
        :data="tableDataShow"
        border
        style="width: 1079px">
      <el-table-column
          prop="id"
          width="130"
          label="工号"
          fixed="left">
      </el-table-column>
      <el-table-column
          width="130"
          prop="username"
          label="姓名"
          fixed="left">
      </el-table-column>
      <el-table-column
          width="130"
          prop="job"
          label="职位">
      </el-table-column>
      <el-table-column
          width="130"
          prop="phone"
          label="联系电话">
      </el-table-column>
      <el-table-column
          width="90"
          prop="process[0]"
          label="岗位1">
      </el-table-column>
      <el-table-column
          width="90"
          prop="process[1]"
          label="岗位2">
      </el-table-column>
      <el-table-column
          width="90"
          prop="process[2]"
          label="岗位3">
      </el-table-column>
      <el-table-column
          width="90"
          prop="process[3]"
          label="岗位4">
      </el-table-column>
      <el-table-column
          width="90"
          prop="process[4]"
          label="岗位5">
      </el-table-column>
      <el-table-column
          width="200"
          prop="leadinglist"
          label="涉及工序">
      </el-table-column>
      <el-table-column
          fixed="right"
          label="操作"
          width="150">
        <template slot-scope="scope">
          <el-button @click="up(scope.row),upOrNew = 'up'" type="text" size="small">编辑</el-button>
        </template>
      </el-table-column>
    </el-table>
    <div style="margin: 10px 0">
      <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage4"
          :page-sizes="[5, 10, 20]"
          :page-size="size"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total">
      </el-pagination>
    </div>
    <!--  弹窗  -->
    <el-dialog :visible.sync="dialogVisible" width="30%" :before-close="handleClose">
      <el-form :model="form" label-width="80px">
        <el-form-item label="姓名">
          <el-input v-model="form.username" style="width: 90%"></el-input>
        </el-form-item>
        <el-form-item label="电话">
          <el-input v-model="form.phone" style="width: 90%"></el-input>
        </el-form-item>
        <el-form-item label="密码" v-if="false">
          <el-input v-model="form.password" style="width: 90%"></el-input>
        </el-form-item>
        <el-form-item label="岗位">
          <el-input v-model="form.job" style="width: 90%"></el-input>
        </el-form-item>
        <el-form-item label="工序选择">
          <el-select v-model="form.process" multiple placeholder="请选择">
            <el-option
                v-for="item in options"
                :key="item"
                :label="item"
                :value="item">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="涉及工序选择" v-if="form.isLeader">
          <el-select v-model="form.leadinglist" multiple placeholder="请选择">
            <el-option
                v-for="item in options"
                :key="item"
                :label="item"
                :value="item">
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="save">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>

import request from "@/utils/request";

export default {
  data() {
    return {
      options: [],
      form:{},
      dialogVisible: false,
      search:'',
      currentPage4:1,
      size:10,
      total:0,
      tableData: [],
      tableDataShow: [],
      upOrNew: '',
    }
  },
  //加载表格
  mounted() {
    this.select()
    this.GetGongxu()
  },
  methods:{
    //获取加工工序
    GetGongxu() {
      request.post("/GetGongxu").then(res => {
        this.options = res.data
      })
    },
    //新增
    add(){
      this.dialogVisible = true
      this.form = {}
      this.upOrNew = 'new'
    },
    //编辑表单回显
    up(row){
      this.form = {
        id : row.id,
        username : row.username,
        phone : row.phone,
        password : row.password,
        job : row.job,
        isLeader : row.isLeader,
        process : row.process,
        leadinglist : row.leadinglist,
      }
      this.dialogVisible = true
    },
    //保存
    save(){
      if (this.upOrNew == 'new'){
        request.post("/AddEmployee",this.form).then(res =>{
          if (res.code == "200"){
            this.$notify({
              title: '成功',
              message: '修改成功',
              type: 'success'
            });
            //this.tableData.push(this.form)
            this.dialogVisible = false
            this.form = {}
            this.select();
          }else {
            this.$notify.error({
              title: '错误',
              message: res.code + ":" + res.msg
            });
          }
        })
      } else if (this.upOrNew == 'up'){
        request.post("/EmployeeAuthority",this.form).then(res =>{
          if (res.code == "200"){
            this.$notify({
              title: '成功',
              message: '编辑成功',
              type: 'success'
            });
            //this.tableData.push(this.form)
            this.dialogVisible = false
            this.form = {}
            this.select();
          }else {
            this.$notify.error({
              title: '错误',
              message: res.code + ":" + res.msg
            });
          }
        })
      }else {
        this.$notify.error({
          title: '错误',
          message: '意外的人员添加或修改'
        });
      }

    },
    //整表查询
    select(){
      request.post("/EmployeeManagement").then(res =>{
        this.tableData = res.data;
        this.tableShow()})
    },
    //关键字查询
    selectkey(){
      this.tableData = [];
      this.tableDataShow = [];
      if (parseInt(this.search) >0 ){
        request.post("/EmployeeAuthoritySelectId",parseInt(this.search)).then(res =>{
          this.tableData.push(res.data);
          this.tableShow()
        })
      } else {
        request.post("/EmployeeAuthoritySelectName",this.search).then(res =>{
          this.tableData.push(res.data);
          this.tableShow()
        })
      }
    },
    tableShow(){
      this.total = this.tableData.length;
      this.tableDataShow = this.tableData.slice((this.currentPage4-1)*this.size,this.currentPage4*this.size);
    },
    //编辑
    update(){
      request.post("/EmployeeAuthority",this.form).then(res =>{
        if (res.code == "200"){
          this.$notify({
            title: '成功',
            message: '修改成功',
            type: 'success'
          });
          this.dialogVisible = false
          this.form = {}
          this.select();
        }else {
          this.$notify.error({
            title: '错误',
            message: res.code + ":" + res.msg
          });
        }
      })
    },
    //重置
    remove(){
      this.search=''
      this.select();
    },
    handleClose(done) {
      this.$confirm('确认关闭？')
          .then(_ => {
            done();
          })
          .catch(_ => {});
    },
    handleSizeChange(val) {
      this.size = val;
      this.tableDataShow = this.tableData.slice((this.currentPage4-1)*this.size,this.currentPage4*this.size);

    },
    handleCurrentChange(val) {
      this.currentPage4 = val
      this.tableDataShow = this.tableData.slice((this.currentPage4-1)*this.size,this.currentPage4*this.size);
    },
    handleEdit(row){
    }
  }
}

</script>

<style scoped>

</style>